import React from 'react';
import { Helmet } from 'react-helmet';
import { useCompanyInfo } from '@/hooks';

interface HelmetProps {
  page: string;
}

const HelmetComponent: React.FC<HelmetProps> = ({ page }) => {
  const { name, description } = useCompanyInfo();
  return (
    <Helmet>
      <title>{page === `Home` ? name : `${name} | ${page}`}</title>
      <meta
        name="description"
        content={`Page: ${page}, Description: ${description}`}
      />
      <meta name="author" content="Weblabs LLC" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="Nursing Facility, Senior Care, Senior Living, Congregational Facility, Patient, Traumatic Injury"
      />
      <meta charSet="utf-8" />
    </Helmet>
  );
};

export default HelmetComponent;
