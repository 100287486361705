import React from 'react';
import { Box, List, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
    background: theme.palette.primary.main,
  },
  navItems: {
    textAlign: `center`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    '& h5': {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(0.3),
      textTransform: `uppercase`,
    },
  },
}));

interface MenuDrawerProps {
  children: React.ReactNode;
}

const MenuDrawer: React.FC<MenuDrawerProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <List className={classes.navItems}>{children}</List>
    </Box>
  );
};

export default MenuDrawer;
