import React from 'react';
import { makeStyles, ListItem, Typography } from '@material-ui/core';
import { Link } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme) => ({
  navItem: {
    borderBottom: `1px solid transparent`,
    paddingBottom: 0,
    paddingTop: 0,
    transition: `border-bottom 0.1s linear`,
    width: `auto`,
    '&:hover': {
      borderBottom: `1px solid white`,
    },
  },
  whiteText: {
    color: theme.palette.primary.contrastText,
  },
}));

interface MenuItemsProps {
  className?: string;
}
const MenuItems: React.FC<MenuItemsProps> = ({ className }) => {
  const classes = useStyles();
  return (
    <>
      <ListItem className={`${classes.navItem} ${className}`}>
        <Link
          style={{ textDecoration: `none` }}
          className={classes.whiteText}
          to="/"
        >
          <Typography variant="h5">Home</Typography>
        </Link>
      </ListItem>
      <ListItem className={`${classes.navItem} ${className}`}>
        <Link
          style={{ textDecoration: `none` }}
          className={classes.whiteText}
          to="/about"
        >
          <Typography variant="h5">About</Typography>
        </Link>
      </ListItem>
      <ListItem className={`${classes.navItem} ${className}`}>
        <Link
          style={{ textDecoration: `none` }}
          className={classes.whiteText}
          to="/services"
        >
          <Typography variant="h5">Services</Typography>
        </Link>
      </ListItem>
      <ListItem className={`${classes.navItem} ${className}`}>
        <Link
          style={{ textDecoration: `none` }}
          className={classes.whiteText}
          to="/contact"
        >
          <Typography variant="h5">Contact Us</Typography>
        </Link>
      </ListItem>
    </>
  );
};

export default MenuItems;
