import React from 'react';
import { Fab, Zoom, useScrollTrigger } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';

const ScrollTopButton: React.FC = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      `#floating-nav-buttons`,
    );

    if (anchor) {
      window.scrollTo({ top: 0 });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation">
        <Fab color="secondary" size="medium" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </div>
    </Zoom>
  );
};

export default ScrollTopButton;
