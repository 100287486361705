import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { CardActionArea } from 'gatsby-theme-material-ui';
import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  image: {
    height: `100%`,
    width: `100%`,
  },
  imageBox: {
    height: `100%`,
    left: 0,
    position: `absolute`,
    top: 0,
    width: `100%`,
    zIndex: -1,
  },
  root: {
    display: `flex`,
    justifyContent: `center`,
    padding: theme.spacing(20, 5),
    position: `relative`,
    [theme.breakpoints.down(`sm`)]: {
      padding: theme.spacing(15, 10),
    },
    [theme.breakpoints.down(`xs`)]: {
      padding: theme.spacing(10, 5),
    },
  },
  text: {
    color: `white`,
    fontSize: theme.spacing(6),
    fontWeight: `bolder`,
    textAlign: `center`,
    [theme.breakpoints.down(`sm`)]: {
      fontSize: theme.spacing(5),
    },
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(3.5),
    },
  },
}));

interface BannerProps {
  image: IGatsbyImageData;
  pageLink: string;
  text: string;
}

const Banner: React.FC<BannerProps> = ({ image, pageLink, text }) => {
  const classes = useStyles();
  return (
    <CardActionArea to={pageLink}>
      <Box className={classes.root}>
        <Box className={classes.imageBox}>
          <GatsbyImage
            className={classes.image}
            image={getImage(image)}
            alt={text}
          />
        </Box>
        <Box className={classes.text}>
          <Typography variant="h2" className={classes.text}>
            {text}
          </Typography>
        </Box>
      </Box>
    </CardActionArea>
  );
};

export default Banner;
