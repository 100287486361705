import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles, Box, Button, Typography } from '@material-ui/core';
import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const useStyles = makeStyles((theme) => ({
  buttonFilled: {
    fontSize: theme.spacing(3),
    height: theme.spacing(6),
    margin: `0 auto`,
    marginTop: theme.spacing(5),
    opacity: 0.85,
    width: theme.spacing(30),
    [theme.breakpoints.down(`sm`)]: {
      fontSize: theme.spacing(3),
      height: theme.spacing(5.5),
      marginTop: theme.spacing(4),
      width: theme.spacing(25),
    },
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(2),
      height: theme.spacing(5),
      marginTop: theme.spacing(3),
      width: theme.spacing(20),
    },
    '&:hover': {
      background: `#8f795e`,
    },
  },
  hero: {
    display: `flex`,
    flexDirection: `column`,
    marginTop: theme.spacing(13),
    maxWidth: theme.breakpoints.width(`md`),
    padding: theme.spacing(0, 10),
    textAlign: `center`,
    [theme.breakpoints.down(`xs`)]: {
      marginTop: theme.spacing(6),
    },
  },
  image: {
    height: `100%`,
    width: `100%`,
    zIndex: -1,
  },
  root: {
    alignItems: `center`,
    display: `flex`,
    height: ({ isHome }: HeaderStyle) => (isHome ? `100vh` : theme.spacing(80)),
    justifyContent: `center`,
    position: `relative`,
    [theme.breakpoints.down(`md`)]: {
      height: ({ isHome }: HeaderStyle) => !isHome && theme.spacing(60),
    },
    [theme.breakpoints.down(`xs`)]: {
      height: ({ isHome }: HeaderStyle) => !isHome && theme.spacing(30),
    },
  },
  slide: {
    backgroundColor: `${theme.palette.primary.dark}b3`,
    height: ({ isHome }: HeaderStyle) => (isHome ? `100vh` : theme.spacing(80)),
    width: `100%`,
    [theme.breakpoints.down(`md`)]: {
      height: ({ isHome }: HeaderStyle) => !isHome && theme.spacing(60),
    },
    [theme.breakpoints.down(`xs`)]: {
      height: ({ isHome }: HeaderStyle) =>
        isHome ? `100vh` : theme.spacing(30),
    },
  },
  slideShow: {
    position: `absolute`,
    left: 0,
    top: 0,
    backgroundColor: theme.palette.grey.contrastText,
    height: ({ isHome }: HeaderStyle) => (isHome ? `100vh` : theme.spacing(60)),
    [theme.breakpoints.down(`xs`)]: {
      height: ({ isHome }: HeaderStyle) =>
        isHome ? `100vh` : theme.spacing(30),
    },
    width: `100%`,
    zIndex: -1,
  },
  subtitle: {
    color: theme.palette.primary.contrastText,
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(2.2),
    },
  },
  title: {
    color: theme.palette.primary.contrastText,
  },
}));

interface HeaderStyle {
  isHome: boolean;
}
interface HeaderProps {
  isHome?: boolean;
  images: IGatsbyImageData[];
  subtitle?: string;
  title: string;
}

const Header: React.FC<HeaderProps> = ({ isHome, title, subtitle, images }) => {
  const classes = useStyles({ isHome });

  return (
    <Box className={classes.root}>
      <Box className={classes.slideShow}>
        <Fade arrows={false} duration={3500} infinite transitionDuration={1500}>
          {images.map((image) => (
            <Box className={classes.slide} key={JSON.stringify(image)}>
              <GatsbyImage
                className={classes.image}
                image={getImage(image)}
                alt={title}
              />
            </Box>
          ))}
        </Fade>
      </Box>
      <Box className={classes.hero} maxWidth="sm">
        {subtitle && (
          <Typography variant="h5" className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        {isHome && (
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonFilled}
            onClick={() => {
              navigate(`/about`);
            }}
          >
            Learn More
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Header;
