import React from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(14, 4),
    [theme.breakpoints.down(`sm`)]: {
      padding: theme.spacing(8, 3),
    },
  },
  subtitle: {
    textAlign: `center`,
    lineHeight: theme.spacing(0.2),
    [theme.breakpoints.down(`sm`)]: {
      fontSize: theme.spacing(2.5),
    },
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(2),
    },
  },
  text: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down(`sm`)]: {
      marginBottom: theme.spacing(8),
    },
    textAlign: `center`,
  },
  title: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(`sm`)]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

interface SectionProps {
  title?: string;
  subtitle?: string;
  className?: string;
}

const Section: React.FC<SectionProps> = ({
  title,
  subtitle,
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <Container className={`${classes.root} ${className}`} maxWidth={false}>
      <Container className={title && classes.text} maxWidth="md">
        {title && (
          <Typography className={classes.title} variant="h2">
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography className={classes.subtitle} variant="h6">
            {subtitle}
          </Typography>
        )}
      </Container>
      {children}
    </Container>
  );
};

export default Section;
