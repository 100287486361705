import React, { useState } from 'react';
import { Fab, Typography, makeStyles } from '@material-ui/core';
import { Phone } from '@material-ui/icons';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    transition: `width 0.5s`,
    height: `48px`,
    borderRadius: `24px`,
    width: (expand) => (expand ? `285px` : `48px`),
    justifyContent: `flex-start`,
    alignItems: `center`,
    paddingLeft: `13px`,
    [theme.breakpoints.down(`xs`)]: {
      width: (expand) => (expand ? `250px` : `48px`),
    },
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  text: {
    overflow: `hidden`,
    whiteSpace: `nowrap`,
    textOverflow: `clip`,
    marginLeft: (expand) => (expand ? `8px` : `15px`),
    paddingTop: theme.spacing(0.5),
  },
  link: {
    display: (expand) => (expand ? `block` : `none`),
    textDecoration: `none`,
    overflow: `hidden`,
    color: `inherit`,
  },
}));

const CallButton: React.FC = () => {
  const [expand, setExpand] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles(expand);

  const handleMouseEnter = () => {
    if (!expand) {
      setExpand(true);
    }
  };

  const handleMouseLeave = () => {
    setExpand(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role="presentation"
    >
      <Fab
        color="primary"
        className={classes.button}
        size="medium"
        variant="circular"
        aria-label="Call us"
      >
        <Phone />
        <a href="tel:323-643-4880" className={classes.link}>
          <Typography className={classes.text}>
            {t(`Call us at 323-643-4880`)}
          </Typography>
        </a>
      </Fab>
    </div>
  );
};

export default CallButton;
