import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { IDayHour } from './types';

interface ICompanyInfo {
  name: string;
  address: string;
  description: string;
  phoneNumber: string;
  faxNumber: string;
  coordinate: {
    lat: number;
    lng: number;
  };
  logo: IGatsbyImageData;
  mobileLogo: IGatsbyImageData;
  hours: IDayHour[];
}

export const useCompanyInfo = (): ICompanyInfo => {
  const data = useStaticQuery(graphql`
    query {
      company {
        name
        address
        description
        faxNumber
        phoneNumber
        coordinate {
          lat
          lng
        }
        hours {
          day
          end
          start
        }
        logo {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
        mobileLogo {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
    }
  `);
  return data.company ?? {};
};
