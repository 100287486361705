import React from 'react';
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  ImageList,
  ImageListItem,
  Container,
} from '@material-ui/core';
import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  image: {
    height: `100%`,
    width: `100%`,
  },
  imageBox: {
    height: `100%`,
    left: 0,
    position: `absolute`,
    top: 0,
    width: `100%`,
    zIndex: -1,
  },
  root: {
    display: `flex`,
    justifyContent: `center`,
    padding: theme.spacing(20, 5),
    position: `relative`,
    [theme.breakpoints.down(`sm`)]: {
      padding: theme.spacing(15, 10),
    },
    [theme.breakpoints.down(`xs`)]: {
      padding: theme.spacing(10, 5),
    },
  },
  text: {
    color: `white`,
    fontSize: theme.spacing(6),
    fontWeight: `bolder`,
    textAlign: `center`,
    [theme.breakpoints.down(`sm`)]: {
      fontSize: theme.spacing(5),
    },
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(3.5),
    },
  },
}));

interface GalleryProps {
  images: IGatsbyImageData[];
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.only(`xs`));
  const isTablet = useMediaQuery(theme.breakpoints.only(`sm`));
  let columns = 3;
  if (isMobile) {
    columns = 1;
  } else if (isTablet) {
    columns = 2;
  }
  return (
    <Container maxWidth="lg">
      <ImageList cols={columns} gap={8}>
        {images.map((image) => (
          <ImageListItem key={JSON.stringify(image)}>
            <GatsbyImage
              key={JSON.stringify(image)}
              className={classes.image}
              image={getImage(image)}
              alt="description"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Container>
  );
};

export default Gallery;
