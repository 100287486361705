import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
  Box,
  ClickAwayListener,
  Fade,
} from '@material-ui/core';
import Navbar from '@/components/navbar/Navbar';

const useStyles = makeStyles((theme) => ({
  fixed: {
    position: `fixed`,
    top: 0,
    width: `100%`,
    zIndex: 2,
  },
  menuItem: {
    margin: theme.spacing(3),
    padding: theme.spacing(1),
    '& h5': {
      fontSize: theme.spacing(3),
      textTransform: `uppercase`,
    },
    [theme.breakpoints.down(`md`)]: {
      '& h5': {
        fontSize: theme.spacing(2.6),
      },
    },
  },
  menuItemDrawer: {
    margin: theme.spacing(0.5),
    '& h5': {
      fontSize: theme.spacing(2.2),
      textTransform: `uppercase`,
    },
  },
  menuItemFixed: {
    margin: theme.spacing(0, 1),
    '& h5': {
      fontSize: theme.spacing(2.2),
      textTransform: `uppercase`,
    },
  },
  root: {
    position: `absolute`,
    width: `100%`,
    zIndex: 2,
  },
  logoInvertedColor: {
    filter: `brightness(0) invert(1)`,
  },
}));

const NavbarContainer: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const expandMenu = useMediaQuery(theme.breakpoints.up(`md`));
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const fixNavbar = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });

  useEffect(() => isDrawerOpen && setIsDrawerOpen(false), [
    expandMenu,
    fixNavbar,
  ]);

  return (
    <ClickAwayListener
      onClickAway={() => isDrawerOpen && setIsDrawerOpen(false)}
    >
      <Box className={classes.root}>
        <Navbar
          classNames={{
            menuItemsRoot: classes.menuItem,
            menuItemsDrawer: classes.menuItemDrawer,
          }}
          expandMenu={expandMenu}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          color="transparent"
        />
        <Fade in={fixNavbar}>
          <Box className={classes.fixed}>
            <Navbar
              classNames={{
                menuItemsRoot: classes.menuItemFixed,
                menuItemsDrawer: classes.menuItemDrawer,
              }}
              expandMenu={expandMenu}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              color={theme.palette.primary.main}
              fixed
            />
          </Box>
        </Fade>
      </Box>
    </ClickAwayListener>
  );
};

export default NavbarContainer;
