import React, { useState } from 'react';
import {
  makeStyles,
  Box,
  Backdrop,
  Fade,
  Modal,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { IModal } from '@/types';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:focus': {
      outline: `none`,
    },
    '&:focus-visible': {
      outline: `none`,
    },
    position: `absolute`,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    backgroundColor: `white`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`,
    minHeight: theme.spacing(30),
    minWidth: theme.spacing(55),
    maxWidth: `100%`,
    width: `max-content`,
    [theme.breakpoints.down(`xs`)]: {
      minWidth: 0,
      width: `100%`,
      height: `inherit`,
    },
  },
  closeIcon: {
    position: `absolute`,
    top: 5,
    right: 0,
  },
  threeBars: {
    height: theme.spacing(1.5),
    width: `100%`,
    position: `absolute`,
    top: 0,
    display: `flex`,
  },
  leftBar: {
    width: `10%`,
    backgroundColor: theme.palette.primary.main,
  },
  middleBar: {
    width: `30%`,
    backgroundColor: theme.palette.primary.light,
  },
  rightBar: {
    width: `60%`,
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    flex: 1,
    padding: theme.spacing(6.5),
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`,
    height: `100%`,
    [theme.breakpoints.down(`xs`)]: {
      padding: theme.spacing(6.5, 3),
    },
  },
}));

const StyledModal: React.FC<IModal> = ({
  children,
  dismissable,
  openModal,
  toggleOpenModal,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={openModal}
      onClose={dismissable && toggleOpenModal}
      aria-labelledby="modal"
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={openModal}>
        <Box className={classes.root}>
          {dismissable && (
            <IconButton onClick={toggleOpenModal} className={classes.closeIcon}>
              <Close />
            </IconButton>
          )}

          <Box className={classes.threeBars}>
            <Box className={classes.leftBar} />
            <Box className={classes.middleBar} />
            <Box className={classes.rightBar} />
          </Box>
          <Box className={classes.content}>{children}</Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default StyledModal;
