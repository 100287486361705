import React from 'react';
import { Toolbar, makeStyles } from '@material-ui/core';
import CallButton from './CallButton';
import ScrollTopButton from './ScrollTopButton';

const useStyles = makeStyles((theme) => ({
  root: {
    position: `fixed`,
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    display: `flex`,
    flexDirection: `column`,
    gap: theme.spacing(1.5),
    alignItems: `flex-end`,
  },
}));

const FloatingNavButtons: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root} id="floating-nav-buttons">
      <ScrollTopButton />
      <CallButton />
    </div>
  );
};

export default FloatingNavButtons;
