import React from 'react';
import { makeStyles, Box, CssBaseline } from '@material-ui/core';
import LanguageModal from '@/components/modals/LanguageModal';
import Navbar from '@/components/navbar';
import Footer from '@/components/Footer';
import FloatingNavButtons from '@/components/floating-nav-buttons';

const useStyles = makeStyles(() => ({
  root: {
    display: `flex`,
    flexDirection: `column`,
    minHeight: `100vh`,
  },
}));

const Layout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CssBaseline />
      <LanguageModal />
      <Navbar />
      {children}
      <FloatingNavButtons />
      <Footer />
    </Box>
  );
};

export default Layout;
