import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import {
  makeStyles,
  useMediaQuery,
  useTheme,
  AppBar,
  Container,
  Collapse,
  IconButton,
  List,
  ListItem,
  Toolbar,
} from '@material-ui/core';
import { Menu, Close } from '@material-ui/icons';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { useCompanyInfo } from '@/hooks';
import MenuDrawer from '@/components/navbar/MenuDrawer';
import MenuItems from '@/components/navbar/MenuItems';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: ({ isDrawerOpen, color }: StyleProps) =>
      isDrawerOpen ? theme.palette.primary.main : color,
    borderBottom: ({ isDrawerOpen }: StyleProps) =>
      isDrawerOpen ? `solid 1px ${theme.palette.primary.light}` : `none`,
    boxShadow: `none`,
    transition: `background-color 300ms linear`,
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
  toolBar: {
    padding: 0,
  },
  navBar: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  logo: {
    maxWidth: theme.spacing(30),
    [theme.breakpoints.down(`xs`)]: {
      padding: theme.spacing(0.5, 0),
      maxWidth: theme.spacing(20),
    },
    margin: `auto`,
  },
  navItems: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `center`,
    marginRight: theme.spacing(2),
    width: `auto`,
  },
  navLogo: {
    '&:hover': {
      backgroundColor: `inherit`,
    },
    '&:focus': {
      backgroundColor: `inherit`,
    },
    margin: `auto 0`,
    [theme.breakpoints.down(`xs`)]: {
      padding: theme.spacing(0.5, 0),
    },
  },
  logoInvertedColor: {
    filter: `brightness(0) invert(1)`,
  },
}));

interface StyleProps {
  color: string;
  isDrawerOpen: boolean;
}

interface NavbarProps {
  classNames: {
    menuItemsDrawer: string;
    menuItemsRoot: string;
  };
  color: string;
  expandMenu: boolean;
  isDrawerOpen: boolean;
  setIsDrawerOpen(open: boolean): any;
  fixed?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({
  classNames,
  color,
  expandMenu,
  isDrawerOpen,
  setIsDrawerOpen,
  fixed,
}) => {
  const { name, logo, mobileLogo } = useCompanyInfo();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(`xs`));
  const shouldInvertColor = fixed || isDrawerOpen;
  const classes = useStyles({ isDrawerOpen, color });

  return (
    <>
      <AppBar className={classes.appBar} position="static">
        <Toolbar component="nav" className={classes.toolBar}>
          <Container maxWidth="xl" className={classes.navBar}>
            <Link to="/" className={classes.navLogo}>
              <ListItem className={classes.navLogo}>
                <GatsbyImage
                  className={`${classes.logo} ${
                    shouldInvertColor && classes.logoInvertedColor
                  }`}
                  image={getImage(isMobile ? mobileLogo : logo)}
                  alt={name}
                  objectFit="contain"
                />
              </ListItem>
            </Link>
            {expandMenu ? (
              <List className={classes.navItems}>
                <MenuItems className={classNames.menuItemsRoot} />
              </List>
            ) : (
              <IconButton
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                edge="start"
                aria-label="menu"
              >
                {isDrawerOpen ? (
                  <Close className={classes.icon} />
                ) : (
                  <Menu className={classes.icon} />
                )}
              </IconButton>
            )}
          </Container>
        </Toolbar>
      </AppBar>
      <Collapse in={isDrawerOpen}>
        <MenuDrawer>
          <MenuItems className={classNames.menuItemsDrawer} />
        </MenuDrawer>
      </Collapse>
    </>
  );
};

export default Navbar;
