import React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { useCompanyInfo } from '@/hooks';

const ENGLISH = `en` as string;
const KOREAN = `ko` as string;

const useStyles = makeStyles((theme) => ({
  center: {
    display: `flex`,
    justifyContent: `center`,
    margin: theme.spacing(2, 0),
    textAlign: `center`,
  },
  icon: {
    fill: theme.palette.secondary.main,
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  navItem: {
    margin: `0 auto`,
    padding: theme.spacing(0.5, 0),
    '& h6': {
      fontFamily: `Avenir`,
      fontSize: theme.spacing(2.3),
      textAlign: `center`,
      textTransform: `uppercase`,
      [theme.breakpoints.down(`sm`)]: {
        fontSize: theme.spacing(2),
      },
    },
  },
  navList: {
    margin: `0 auto`,
    maxWidth: theme.breakpoints.width(`sm`),
    paddingTop: 0,
  },
  removeLink: {
    color: `inherit`,
    lineHeight: 0.5,
    textDecoration: `none`,
  },
  root: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    marginTop: `auto`,
    padding: theme.spacing(3, 2),
  },
  socialMediaList: {
    display: `flex`,
    justifyContent: `center`,
    margin: theme.spacing(2, 0),
    padding: 0,
  },
  top: {
    display: `flex`,
    justifyContent: `center`,
    margin: `0 auto`,
  },
}));

const navItems = [
  {
    name: `Home`,
    link: `/`,
  },
  {
    name: `About Us`,
    link: `/about`,
  },
  {
    name: `Services`,
    link: `/services`,
  },
  {
    name: `Contact Us`,
    link: `/contact`,
  },
];

const LanguageSelect: React.FC = () => {
  const { originalPath } = useI18next();
  const classes = useStyles();

  return (
    <Container maxWidth="xs" style={{ margin: `0 auto` }}>
      <Grid container className={classes.center}>
        <Grid item xs={12} sm={6}>
          <Link
            className={classes.removeLink}
            language={ENGLISH}
            to={originalPath}
          >
            <Typography variant="body1">English</Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Link
            className={classes.removeLink}
            language={KOREAN}
            to={originalPath}
          >
            <Typography variant="body1">한국어</Typography>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

const Nav: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.navList}>
      {navItems.map(({ name, link }) => (
        <Grid key={name} item xs={6} sm={3} className={classes.navItem}>
          <Link className={classes.removeLink} to={link}>
            <Typography variant="h6">{name}</Typography>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

const Top: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.top}>
      <Grid item xs={12}>
        <Nav />
      </Grid>
      <Grid item xs={12}>
        <LanguageSelect />
      </Grid>
    </Grid>
  );
};

const Middle: React.FC = () => {
  const { name, address, phoneNumber, faxNumber } = useCompanyInfo();
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="xs" style={{ margin: `0 auto` }}>
        <Grid container className={classes.center}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <a
                className={classes.removeLink}
                href={`tel:+1${phoneNumber}`}
              >{`Phone ${phoneNumber}`}</a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              <a
                className={classes.removeLink}
                href={`tel:+1${faxNumber}`}
              >{`Fax ${faxNumber}`}</a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container
        className={classes.center}
        maxWidth="md"
        style={{ margin: `0 auto` }}
      >
        <Typography variant="body1">{`${name} ${address}`}</Typography>
      </Container>
    </>
  );
};

const Copyright: React.FC = () => {
  const { name } = useCompanyInfo();
  return (
    <Typography variant="body1">
      {` Copyright © ${name} `}
      {new Date().getFullYear()}.
    </Typography>
  );
};

const Bottom: React.FC = () => {
  const classes = useStyles();
  return (
    <Container
      className={classes.center}
      maxWidth="md"
      style={{ display: `flex`, flexWrap: `wrap`, margin: `0 auto` }}
    >
      <Link className={classes.removeLink} to="/">
        <Typography variant="body1">Privacy Policy Sitemap &nbsp;</Typography>
      </Link>
      <Copyright />
    </Container>
  );
};

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Box maxWidth="md" padding={0}>
        <Top />
        <Middle />
        <Bottom />
      </Box>
    </footer>
  );
};

export default Footer;
