import React, { useState } from 'react';
import { makeStyles, Box, Typography, Modal } from '@material-ui/core';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { Button } from 'gatsby-theme-material-ui';
import StyledModal from './StyledModal';

const ENGLISH = `en` as string;
const KOREAN = `ko` as string;
const LOCAL_STORAGE_KEY_NAME = `language-modal-seen`;

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(5, 7),
    height: `100%`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`,
  },
  initial: {
    color: theme.palette.primary.main,
  },
  welcomeTo: {
    [theme.breakpoints.down(`xs`)]: {
      textAlign: `center`,
    },
  },
  companyName: {
    fontSize: theme.spacing(6),
    textAlign: `center`,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(4),
    },
  },
  lower: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down(`xs`)]: {
      margin: 0,
    },
  },
  selectPrompt: {
    textAlign: `center`,
    fontFamily: `Avenir Medium`,
  },
  buttons: {
    display: `flex`,
    justifyContent: `space-around`,
    gap: theme.spacing(2),
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down(`xs`)]: {
      flexDirection: `column`,
      gap: 0,
      marginBottom: 0,
    },
  },
  button: {
    '&:hover': {
      boxShadow: `none`,
    },
    width: theme.spacing(25),
    height: theme.spacing(8),
    boxShadow: `none`,
    borderRadius: theme.spacing(4),
    [theme.breakpoints.down(`xs`)]: {
      margin: `${theme.spacing(1)}px auto`,
    },
  },
}));

const LanguageModal: React.FC = () => {
  const { changeLanguage } = useI18next();
  const [open, setOpen] = useState(
    typeof window !== `undefined` &&
      localStorage.getItem(LOCAL_STORAGE_KEY_NAME) == null,
  );

  const classes = useStyles();

  const closeModal = (newLanguage) => {
    if (typeof window !== `undefined`) {
      localStorage.setItem(LOCAL_STORAGE_KEY_NAME, `true`);
    }
    changeLanguage(newLanguage);
    setOpen(false);
  };

  return (
    <StyledModal openModal={open}>
      <Box className={classes.main}>
        <Box>
          <Typography variant="h2" className={classes.welcomeTo}>
            Welcome To
          </Typography>
          <Typography variant="h1" className={classes.companyName}>
            <span className={classes.initial}>SH</span> Congregate Facility
          </Typography>
        </Box>
        <Box className={classes.lower}>
          <Typography variant="h6" className={classes.selectPrompt}>
            Select Your Language Preference
          </Typography>
          <Box className={classes.buttons}>
            <Button
              variant="contained"
              size="large"
              className={classes.button}
              onClick={(e) => {
                e.preventDefault();
                closeModal(ENGLISH);
              }}
            >
              English
            </Button>
            <Button
              variant="contained"
              size="large"
              className={classes.button}
              onClick={(e) => {
                e.preventDefault();
                closeModal(KOREAN);
              }}
            >
              한국어
            </Button>
          </Box>
        </Box>
      </Box>
    </StyledModal>
  );
};

export default LanguageModal;
